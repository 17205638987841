import { logErrorAndReportToHoneybadger } from '../../errorReporting';
import { getFeatures, isFeatureActiveForValue } from './client';
import { AvailableFeatures } from './types';

export async function memberCanEnterNewVideoCall(userId: number) {
  let canEnterNewVideoCall = false;
  try {
    const features = await getFeatures([AvailableFeatures.ProviderAllowedNewVideoCall]);
    if (features) {
      canEnterNewVideoCall = isFeatureActiveForValue(
        features[AvailableFeatures.ProviderAllowedNewVideoCall] as string,
        userId
      );
    }
  } catch (error) {
    logErrorAndReportToHoneybadger({ error });
  }
  return canEnterNewVideoCall;
}
