import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  connect,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video';
import EnaraRoom from '../../components/EnaraRoom/EnaraRoom';
import TelemedicineModal from '../../components/TelemedicineModal/TelemedicineModal';
import Toast from '../../components/Toast/Toast';
import { ErrorTypes } from '../../constants/errors';
import {
  ALLOW_CAMERA,
  ALLOW_MICROPHONE,
  ALLOW_SCREEN_SHARING,
  CAMERA_OR_MICROPHONE_IN_USE,
  COULD_NOT_SET_UP_THE_CALL,
  NO_ROOM_INFORMATION,
  SCREEN_SHARING_NOT_SUPPORTED,
} from '../../constants/lang';
import { getFeatures } from '../../lib/api/FeatureService/client';
import { memberCanEnterNewVideoCall } from '../../lib/api/FeatureService/helpers';
import { AvailableFeatures } from '../../lib/api/FeatureService/types';
import { fetchVideoRoomWithToken } from '../../lib/api/Twilio/client';
import { VideoRoomResponse } from '../../lib/api/Twilio/types';
import { PackageVersion } from '../../lib/common/version';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import { getItem, StorageKeys } from '../../lib/localStorage';
import MixpanelClient, {
  LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
  LegacyTrackingEvent,
} from '../../lib/tracking/mixpanel';
import '../../styles/App.scss';
import CallEnded from './Components/CallEnded/CallEnded';
import GetReady from './Components/GetReady/GetReady';

type Props = RouteComponentProps<{ videoToken: string }>;

type State = {
  presenting: boolean;
  data: VideoRoomResponse | null;
  identity: string;
  room: Room | null;
  loadingJoin: boolean;
  loadingPresent: boolean;
  loadingMode: boolean;
  showModalByClinic: boolean;
  showTelemedicineModal: boolean;
  callEnded: boolean;
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: null,
      identity: '',
      room: null,
      presenting: false,
      loadingJoin: false,
      loadingPresent: false,
      loadingMode: false,
      showModalByClinic: false,
      showTelemedicineModal: false,
      callEnded: false,
    };

    this.joinRoom = this.joinRoom.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    this.getFeatureFlags = this.getFeatureFlags.bind(this);
    this.placeOfServiceWasRegistered = this.placeOfServiceWasRegistered.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.checkTelemedicineBeforeJoiningRoom = this.checkTelemedicineBeforeJoiningRoom.bind(this);
  }

  async componentDidMount() {
    try {
      const { videoToken } = this.props.match.params;
      const memberId = new URLSearchParams(window.location.search).get('memberId')!;

      const data = await fetchVideoRoomWithToken({ token: videoToken, memberId });

      if (data) {
        /**
         * First, we need to confirm if the member is allowed to
         * access the new video call or not
         */
        if (
          data.identity_detail?.member_id &&
          (await memberCanEnterNewVideoCall(data.identity_detail.member_id))
        ) {
          /**
           * Then the member can access the new video call,
           * so we'll just redirect to the mobile app
           */
          alert(
            'To join your video call, please open the Enara Health app. You can access the call by: \n' +
              '\t• Tapping the banner in the app or \n' +
              '\t• Clicking the link sent in the chat from your provider'
          );
          window.location.href = 'com.enara://video-call';
          return null;
        }

        this.setState({ data });

        MixpanelClient.registerUser({
          userType: data?.user_type,
          room: data?.room,
          identityDetail: data?.identity_detail,
        });

        MixpanelClient.trackLegacyEventWithTag({
          tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
          eventName: LegacyTrackingEvent.VideoCallAppOpen,
        });

        if (data.identity_detail && Object.keys(data.identity_detail).length > 0) {
          const showTelemedicineModal = await this.getFeatureFlags(data.identity_detail.clinic_id);

          showTelemedicineModal && this.setState({ showModalByClinic: showTelemedicineModal });
        }
      }
    } catch (error) {
      Toast.error(COULD_NOT_SET_UP_THE_CALL);

      logErrorAndReportToHoneybadger({ error });
    }
  }

  async getFeatureFlags(clinicId: number) {
    const { memberShowTelemedicineModalByClinic } = AvailableFeatures;
    const features = await getFeatures([memberShowTelemedicineModalByClinic]);

    let typeOfFeatureFlag;

    if (features) {
      const showTelemedicineByClinic = features[memberShowTelemedicineModalByClinic];
      typeOfFeatureFlag =
        showTelemedicineByClinic === 'true'
          ? true
          : showTelemedicineByClinic === 'false'
          ? false
          : showTelemedicineByClinic;
    }

    if (typeof typeOfFeatureFlag === 'string') {
      const availableClinics = typeOfFeatureFlag.split(',');
      return availableClinics.includes(clinicId.toString());
    }

    if (typeof typeOfFeatureFlag === 'boolean') {
      return typeOfFeatureFlag;
    }
  }

  placeOfServiceWasRegistered(scheduleId: number) {
    const scheduleIdRegistered = getItem(StorageKeys.TelemedicineSchedulingId) as number | null;

    if (scheduleIdRegistered) {
      if (scheduleIdRegistered !== scheduleId) {
        MixpanelClient.trackLegacyEventWithTag({
          tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
          eventName: LegacyTrackingEvent.TelemedicineOpen,
        });

        this.setState({ showTelemedicineModal: true });
      } else {
        this.joinRoom();
      }
    } else {
      MixpanelClient.trackLegacyEventWithTag({
        tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
        eventName: LegacyTrackingEvent.TelemedicineOpen,
      });

      this.setState({ showTelemedicineModal: true });
    }
  }

  closeModal() {
    this.setState({ showTelemedicineModal: false });
  }

  checkTelemedicineBeforeJoiningRoom() {
    MixpanelClient.trackLegacyEventWithTag({
      tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
      eventName: LegacyTrackingEvent.VideoCallAppClickJoinCall,
    });

    if (this.state.showModalByClinic) {
      if (this.state.data?.identity_detail?.schedule_id) {
        this.placeOfServiceWasRegistered(this.state.data.identity_detail.schedule_id);
      } else {
        this.joinRoom();
      }
    } else {
      this.joinRoom();
    }
  }

  async joinRoom() {
    let videoTrack;
    let audioTrack;

    try {
      this.setState({ loadingJoin: true });

      try {
        videoTrack = await createLocalVideoTrack();
      } catch (error: any) {
        logErrorAndReportToHoneybadger({ error });

        if (error?.name === ErrorTypes.NotAllowedError) {
          throw new Error(ALLOW_CAMERA);
        }

        throw error;
      }

      try {
        audioTrack = await createLocalAudioTrack();
      } catch (error: any) {
        logErrorAndReportToHoneybadger({ error });

        if (error?.name === ErrorTypes.NotAllowedError) {
          throw new Error(ALLOW_MICROPHONE);
        }

        throw error;
      }

      if (!this.state.data) {
        Toast.warning(NO_ROOM_INFORMATION);
      }

      // Join the Room with the pre-acquired LocalTracks
      const room = await connect(this.state.data!.token, {
        name: this.state.data!.room,
        tracks: [videoTrack, audioTrack],
      });

      this.setState({ room, presenting: false });
    } catch (error: any) {
      logErrorAndReportToHoneybadger({ error });
      this.setState({ loadingJoin: false });

      if (videoTrack) {
        videoTrack.stop();
      }

      if (audioTrack) {
        audioTrack.stop();
      }

      if (error.name === ErrorTypes.NotReadableError) {
        Toast.error(CAMERA_OR_MICROPHONE_IN_USE);
      } else if (error.message) {
        Toast.error(error.message);
      }
    }
  }

  async toggleMode() {
    this.setState({ loadingMode: true });

    if (!this.state || !this.state.room) {
      Toast.warning(NO_ROOM_INFORMATION);
    }

    try {
      let localVideoTrack;

      if (this.state.presenting) {
        // Presenting screen
        try {
          localVideoTrack = await createLocalVideoTrack();

          MixpanelClient.trackLegacyEventWithTag({
            tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
            eventName: LegacyTrackingEvent.VideoCallAppProviderSwitchToCamera,
          });
        } catch (error: any) {
          logErrorAndReportToHoneybadger({ error });

          if (error.name === ErrorTypes.NotAllowedError) {
            throw new Error(ALLOW_CAMERA);
          }

          throw error;
        }
      } else {
        // Camera
        let stream;

        try {
          stream = await navigator.mediaDevices.getDisplayMedia();
        } catch (error: any) {
          logErrorAndReportToHoneybadger({ error });

          if (error.name === ErrorTypes.TypeError) {
            throw new Error(SCREEN_SHARING_NOT_SUPPORTED);
          } else if (error.name === ErrorTypes.NotAllowedError) {
            throw new Error(ALLOW_SCREEN_SHARING);
          }

          throw error;
        }

        // Catches the stop sharing event and consider that as toggle
        stream.getTracks()[0].addEventListener('ended', () => setTimeout(this.toggleMode, 500));
        localVideoTrack = new LocalVideoTrack(stream.getTracks()[0]);

        MixpanelClient.trackLegacyEventWithTag({
          tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
          eventName: LegacyTrackingEvent.VideoCallAppProviderSwitchToShareScreen,
        });
      }

      const videoTracks = Array.from(this.state.room!.localParticipant.videoTracks.values()).map(
        (publication) => publication.track
      );

      for (const t of videoTracks) {
        t.stop();
      }

      this.state.room!.localParticipant.unpublishTracks(videoTracks);
      this.state.room!.localParticipant.publishTrack(localVideoTrack);

      this.setState({ presenting: !this.state.presenting, loadingMode: false });
    } catch (error: any) {
      logErrorAndReportToHoneybadger({ error });

      this.setState({ loadingMode: false });

      if (error.name === ErrorTypes.NotReadableError) {
        Toast.error(CAMERA_OR_MICROPHONE_IN_USE);
      } else if (error.message) {
        Toast.error(error.message);
      }
    }
  }

  private leaveRoom() {
    MixpanelClient.trackLegacyEventWithTag({
      tag: LEGACY_TRACKING_TAG_VIDEO_CALL_APP,
      eventName: LegacyTrackingEvent.VideoCallAppLeaveCall,
    });

    this.setState({ room: null, loadingJoin: false, loadingPresent: false, callEnded: true });
  }

  render() {
    const { showTelemedicineModal, data, room, loadingJoin, presenting, loadingMode, callEnded } =
      this.state;

    return (
      <div className='app' data-enara-version={PackageVersion}>
        {callEnded ? (
          <CallEnded />
        ) : (
          <>
            <TelemedicineModal
              modalVisibility={showTelemedicineModal}
              meetingData={data}
              setModalVisibility={this.closeModal}
              onJoinRoom={this.joinRoom}
            />

            {room === null ? (
              <GetReady
                loadingJoin={loadingJoin}
                videoRoomInfoAlreadyObtained={data !== null}
                checkTelemedicineBeforeJoiningRoom={this.checkTelemedicineBeforeJoiningRoom}
              />
            ) : (
              <EnaraRoom
                data={data!}
                room={room}
                presenting={presenting}
                loadingMode={loadingMode}
                onLeaveRoom={this.leaveRoom}
                onToggle={this.toggleMode}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default App;
